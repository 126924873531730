import $ from "jquery";
globalThis.jQuery = $;

import './styles.scss';

import 'slick-carousel';
import 'slick-carousel/slick/slick.scss';
import 'slick-carousel/slick/slick.min.js';

// FontAWesome
import '@fortawesome/fontawesome-pro/js/fontawesome.min.js';
import '@fortawesome/fontawesome-pro/js/brands.min.js';
import '@fortawesome/fontawesome-pro/js/solid.min.js';
import '@fortawesome/fontawesome-pro/js/regular.min.js';



import '@fortawesome/fontawesome-pro/css/regular.min.css';
import '@fortawesome/fontawesome-pro/css/solid.min.css';
import '@fortawesome/fontawesome-pro/css/brands.min.css';


// SKROLLR
import skrollr from 'skrollr';

(function ($) {

  $(function () {

    'use strict';

    // DOM ready, take it away

    // MOBILE MENU submenu
    $('<div class="subarrow"><i class="fa-regular fa-chevron-down"></i></div>').insertAfter('.mobile-menu li.menu-item-has-children > a');
    $('.mobile-menu .subarrow').on('click', function () {
      if ($(this).parent().hasClass('active')) {
        $(this).parent().removeClass('active');
      } else {
        $(this).parent().addClass('active');
      }
    });



    // MOBILE TOGGLE
    // ===============================================

    $('.menu-toggle').on('click', function () {
      if ($('.mobile-menu').hasClass('active')) {
        $('.mobile-menu, .page-overlay').removeClass('active');
        $('body').removeClass('overflow-hidden');
      } else {
        $('.mobile-menu, .page-overlay').addClass('active');
        $('body').addClass('overflow-hidden');
      }
    });
    $('.page-overlay').on('click', function () {
      $('.mobile-menu, .page-overlay').removeClass('active');
      $('body').removeClass('overflow-hidden');
    });

    $('.mobile-menu ul li a, .header .logo').on('click', function () {
      $('body').removeClass('overflow-hidden');
    });

    // Close mobile menu on click
    $(document).click(function (event) {
      var $target = $(event.target);
      if (!$target.closest('.mobile-menu').length && !$target.closest('.menu-toggle').length && $('.mobile-menu').hasClass('active')) {
        $('.mobile-menu').removeClass('active');
        $('body').removeClass('overflow-hidden');
      }

    });


    // FILTER TOGGLE
    // ===============================================
    $('.filter-toggle').on('click', function () {
      if ($('.searchandfilter').hasClass('active')) {
        $('.searchandfilter').removeClass('active');
      } else {
        $('.searchandfilter').addClass('active');
      }
    });


    // HEADER SCROLLED
    // ===============================================
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();
      if (scroll >= 150) {
        $("header, body").addClass("scrolled");
      }

      if (scroll < 50) {
        $("header, body").removeClass("scrolled");
      }
    });


    // ADD IN MIDDLE OF PAGE
    // ===============================================
    var adMiddlePost = function () {
      // Find middle of page
      var $ones = $('#productloop .productitem');

      // Add div to middle of post
      if ($ones.length <= 6) {
        $('#productloop .productitem:nth-child(6)').after('<div class="col-span-1 lg:col-span-3 addlocation"></div>')
        $(".cta-middleblock").detach().appendTo('.addlocation');
      } else {
        $(".cta-middleblock").hide();
      }


    }
    adMiddlePost();




    // SKROLLR
    // ===============================================
    window.onload = function() {
      var s = skrollr.init({
        forceHeight: false
      });

      if (s.isMobile()) {
        s.destroy();
      }
    };



    // SLIDERS
    // ===============================================
    $('.specialty-slider').slick({
      dots: false,
      arrows: true,
      prevArrow: $('.specialty-prev'),
      nextArrow: $('.specialty-next'),
      infinite: true,
      autoplay: true,
      autoplaySpeed: 8000,
      mobileFirst: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 6,
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 4,
          }
        },
        {
          breakpoint: 568,
          settings: {
            slidesToShow: 2,
          }
        }
        ]
    });

    $('.specialty-slider-small').slick({
      dots: false,
      arrows: true,
      prevArrow: $('.specialty-prev-small'),
      nextArrow: $('.specialty-next-small'),
      infinite: true,
      autoplay: true,
      autoplaySpeed: 8000,
      mobileFirst: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 568,
          settings: {
            slidesToShow: 2,
          }
        }
      ]
    });

    $('.package-slider').slick({
      dots: false,
      arrows: true,
      prevArrow: $('.package-prev'),
      nextArrow: $('.package-next'),
      infinite: true,
      autoplay: true,
      autoplaySpeed: 8000,
      mobileFirst: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 568,
          settings: {
            slidesToShow: 2,
          }
        }
      ]
    });


  });

})(jQuery);